<template>
  <b-card class="border border-dark shadow-none">
    <div>
      <b-row>

        <!-- #region::Title -->
        <b-col class="align-self-end">
          <h4 class="font-weight-bolder">
            Dirección de envío
          </h4>
        </b-col>
        <!-- #endregion::Title -->

        <!-- #region::Collapsable options -->
        <b-col
          cols="12"
          md="auto"
          class="ml-auto"
        >

          <!-- #region begin::Open modal button -->
          <b-button
            v-if="showUseOtherButton"
            variant="flat-primary"
            size="sm"
            :disabled="!canSetShipmentAddress"
            @click="openModal"
          >
            {{ optionsButtonText }}
          </b-button>
          <!-- #endregion end::Open modal button -->

          <!-- #region begin::Collapse card button -->
          <b-button
            v-if="addressCostumer || idShipmentAddress || showToggleButton"
            :variant="visible ? 'flat-primary' : 'flat-secondary'"
            class="btn-icon rounded-circle ml-1"
            @click="collapse"
          >
            <feather-icon :icon="iconToggle" />
          </b-button>
          <!-- #endregion end::Collapse card button -->

        </b-col>
        <!-- #endregion::Collapsable options -->

      </b-row>
    </div>

    <b-collapse
      :id="collapseItemId"
      v-model="visible"
      role="tabpanel"
    >
      <div>
        <hr>
        <h4 class="font-weight-bolder">
          {{ shipmentContact }}
        </h4>
      </div>
      <div class="text-primary">
        {{ shipmentEmail }}
      </div>
      <div>
        {{ shipmentPhone }}
      </div>
      <br>
      <div>
        {{ shipmentAddress }}
      </div>
      <div>
        {{ shipmentZipCode }}
      </div>
      <div>
        {{ shipmentColony }}
      </div>
      <div>
        {{ shipmentCity }}
      </div>
      <div>
        {{ shipmentState }}
      </div>
    </b-collapse>

    <!-- #region::Form modal -->
    <b-modal
      id="invoiceDataModal"
      ref="invoiceDataModal"
      title="Dirección de envío"
      centered
      size="lg"
      @hide="resetValues"
    >
      <b-card-text>

        <b-alert
          show
          variant="info"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="AlertCircleIcon"
            />
            <span class="ml-25">La información modificada solo será parte de esta cotización. Para guardar la información del cliente haz click en el checkbox de abajo.</span>
          </div>
        </b-alert>

        <validation-observer ref="invoiceDataForm">

          <!-- #region::Form -->
          <b-form @submit.stop.prevent="handleSubmit">
            <b-row>

              <!-- #region::Shipment contact name input -->
              <b-col md="6">
                <TextInputWithValidation
                  v-model="formShipmentContact"
                  vid="formShipmentContact"
                  rules="required|min:2"
                  type="text"
                  label="Nombre del contacto"
                  name="contacto"
                  maxlength="200"
                  placeholder="Escribe el nombre del contacto"
                />
              </b-col>
              <!-- #endregion::Shipment contact name input -->

              <!-- #region::Shipment address input -->
              <b-col md="6">
                <TextInputWithValidation
                  v-model="formShipmentAddress"
                  vid="formShipmentAddress"
                  rules="required|min:2"
                  type="text"
                  label="Dirección"
                  name="dirección"
                  maxlength="250"
                  placeholder="Escribe la dirección"
                />
              </b-col>
              <!-- #endregion::Shipment address input -->

              <!-- #region::Shipment zip code input -->
              <b-col md="6">
                <TextInputWithValidation
                  v-model="formShipmentZipCode"
                  vid="formShipmentZipCode"
                  rules="required|digits:5"
                  type="text"
                  label="Código postal"
                  name="código postal"
                  maxlength="5"
                  placeholder="Escribe el código postal"
                  @input="handleShipmentZipCode"
                />
              </b-col>
              <!-- #endregion::Shipment zip code input -->

              <!-- #region::Shipment colony select -->
              <b-col md="6">
                <SelectWithValidation
                  v-model="formShipmentColony"
                  vid="formShipmentColony"
                  rules="required"
                  name="colonia"
                  label="Colonia"
                  property="name"
                  placeholder="Selecciona la colonia"
                  :options="formShipmentColonies"
                  :disabled="formShipmentColonies.length === 0"
                />
              </b-col>
              <!-- #endregion::Shipment colony select -->

              <!-- #region::Shipment city select -->
              <b-col md="6">
                <SelectWithValidation
                  v-model="formShipmentCity"
                  vid="formShipmentCity"
                  rules="required"
                  name="ciudad"
                  label="Ciudad"
                  property="name"
                  placeholder="Selecciona la ciudad"
                  :options="formShipmentCities"
                  :disabled="formShipmentCities.length === 0"
                />
              </b-col>
              <!-- #endregion::Shipment city select -->

              <!-- #region::Shipment state select -->
              <b-col md="6">
                <SelectWithValidation
                  v-model="formShipmentState"
                  vid="formShipmentState"
                  rules="required"
                  name="estado"
                  label="Estado"
                  property="name"
                  placeholder="Selecciona la estado"
                  :options="formShipmentStates"
                  :disabled="formShipmentStates.length === 0"
                />
              </b-col>
              <!-- #endregion::Shipment state select -->

              <!-- #region::Shipment email input -->
              <b-col md="6">
                <TextInputWithValidation
                  v-model="formShipmentEmail"
                  vid="formShipmentEmail"
                  rules="required|email"
                  type="email"
                  label="Correo electrónico"
                  name="correo electrónico"
                  placeholder="Escribe el correo electrónico"
                />
              </b-col>
              <!-- #endregion::Shipment email input -->

              <!-- #region::Shipment phone input -->
              <b-col md="6">
                <TextInputWithValidation
                  v-model="formShipmentPhone"
                  vid="formShipmentPhone"
                  rules="required|digits:10"
                  type="text"
                  label="Teléfono"
                  name="teléfono"
                  maxlength="10"
                  placeholder="Escribe el número telefónico"
                />
              </b-col>
              <!-- #endregion::Shipment phone input -->

              <b-col md="12">
                <b-form-checkbox
                  v-model="updateData"
                  class="mt-1"
                >
                  Actualizar información del cliente
                </b-form-checkbox>
              </b-col>

            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>

      <!-- #region::Footer -->
      <template #modal-footer>
        <b-button
          variant="delete-btn"
          class="delete-btn"
          @click="hideModal"
        >
          Cancelar
        </b-button>
        <b-button
          variant="principal-btn"
          class="principal-btn"
          @click="handleReplaceData"
        >
          Usar estos datos
        </b-button>
      </template>
      <!-- #endregion::Footer -->

    </b-modal>
    <!-- #endregion::Form modal -->

  </b-card>
</template>

<script>
// #region Imports
import { mapActions, mapGetters } from 'vuex'
import { ValidationObserver, localize } from 'vee-validate'
import { required, min } from '@validations'
import {
  BCol, BButton, BCard, BRow, BCollapse, BModal, VBModal, BCardText, BForm, BAlert, BFormCheckbox,
} from 'bootstrap-vue'

import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BCard,
    BAlert,
    BModal,
    BButton,
    BCardText,
    BCollapse,
    BFormCheckbox,
    ValidationObserver,
    SelectWithValidation,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    TextInputWithValidation,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    quote: {
      type: Object,
      default: null,
    },
    shipmentAddressData: {
      type: Object,
      default: null,
    },
    showToggleButton: {
      type: Boolean,
      default: false,
    },
    showUseOtherButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemId: '2',
      iconToggle: 'ChevronDownIcon',

      updateData: false,

      formShipmentCity: '',
      formShipmentState: '',
      formShipmentEmail: '',
      formShipmentPhone: '',
      formShipmentColony: '',
      formShipmentContact: '',
      formShipmentAddress: '',
      formShipmentZipCode: '',

      formShipmentCities: [],
      formShipmentStates: [],
      formShipmentColonies: [],

      // * 31/10/2022 - TAG: Validations
      min,
      required,
    }
  },
  computed: {
    ...mapGetters({
      getIdQuote: 'quotes/getIdQuote',
      getIdCustomer: 'quotes/getIdCustomer',
      getShipmentCity: 'quotes/getShipmentCity',
      getShipmentState: 'quotes/getShipmentState',
      getShipmentEmail: 'quotes/getShipmentEmail',
      getShipmentPhone: 'quotes/getShipmentPhone',
      getShipmentColony: 'quotes/getShipmentColony',
      getShipmentContact: 'quotes/getShipmentContact',
      getShipmentAddress: 'quotes/getShipmentAddress',
      getShipmentZipCode: 'quotes/getShipmentZipCode',
      getSelectedCustomer: 'quotes/getSelectedCustomer',
      getIdShipmentAddress: 'quotes/getIdShipmentAddress',
    }),
    idQuote: {
      get() { return this.getIdQuote },
      set(value) { this.setIdQuote(value) },
    },
    idShipmentAddress: {
      get() { return this.getIdShipmentAddress },
      set(value) { this.setIdShipmentAddress(value) },
    },
    shipmentCity: {
      get() { return this.getShipmentCity },
      set(value) { this.setShipmentCity(value) },
    },
    shipmentState: {
      get() { return this.getShipmentState },
      set(value) { this.setShipmentState(value) },
    },
    shipmentEmail: {
      get() { return this.getShipmentEmail },
      set(value) { this.setShipmentEmail(value) },
    },
    shipmentPhone: {
      get() { return this.getShipmentPhone },
      set(value) { this.setShipmentPhone(value) },
    },
    shipmentColony: {
      get() { return this.getShipmentColony },
      set(value) { this.setShipmentColony(value) },
    },
    shipmentContact: {
      get() { return this.getShipmentContact },
      set(value) { this.setShipmentContact(value) },
    },
    shipmentAddress: {
      get() { return this.getShipmentAddress },
      set(value) { this.setShipmentAddress(value) },
    },
    shipmentZipCode: {
      get() { return this.getShipmentZipCode },
      set(value) { this.setShipmentZipCode(value) },
    },
    selectedCustomer: {
      get() { return this.getSelectedCustomer },
    },
    idCustomer: {
      get() { return this.getIdCustomer },
    },
    addressCostumer() {
      if (this.selectedCustomer) {
        const { address } = this.selectedCustomer

        if (address.length > 0) {
          const [addressCostumer] = address
          return addressCostumer
        }

        return false
      }

      return false
    },
    userCostumer() {
      if (this.selectedCustomer) {
        const { user } = this.selectedCustomer

        if (user.length > 0) {
          const [userCostumer] = user
          return userCostumer
        }

        return false
      }

      return false
    },
    optionsButtonText() {
      if (this.selectedCustomer && !this.addressCostumer && !this.idShipmentAddress) {
        return 'Agregar'
      }

      return 'Usar otra'
    },
    canSetShipmentAddress() {
      if (this.selectedCustomer) {
        return true
      }

      if (this.idShipmentAddress) {
        return true
      }

      if (!this.addressCostumer) {
        return false
      }

      return true
    },
  },
  watch: {
    selectedCustomer() {
      if (this.addressCostumer) {
        this.loadExistingShipmentAddress(this.addressCostumer)
      } else if (this.visible) {
        this.collapse()
      }
    },
  },
  created() {
    if (this.quote) {
      const [addressInfo] = this.quote.address
      this.loadExistingShipmentAddress(addressInfo)
    }

    if (this.shipmentAddressData) {
      this.idShipmentAddress = this.shipmentAddressData.IdAddress
      this.loadExistingShipmentAddress(this.shipmentAddressData)
    }

    localize('es')
  },
  methods: {
    ...mapActions({
      setIdQuote: 'quotes/setIdQuote',
      loadZipCode: 'wholesalers/loadZipCode',
      setShipmentCity: 'quotes/setShipmentCity',
      setShipmentState: 'quotes/setShipmentState',
      setShipmentEmail: 'quotes/setShipmentEmail',
      setShipmentPhone: 'quotes/setShipmentPhone',
      setShipmentColony: 'quotes/setShipmentColony',
      setShipmentContact: 'quotes/setShipmentContact',
      setShipmentAddress: 'quotes/setShipmentAddress',
      setShipmentZipCode: 'quotes/setShipmentZipCode',
      updateShipmentAddress: 'quotes/updateShipmentAddress',
      setIdShipmentAddress: 'quotes/setIdShipmentAddress',
    }),
    collapse() {
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
    openModal() {
      this.$refs.invoiceDataModal.show()
    },
    handleReplaceData(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      const success = await this.$refs.invoiceDataForm.validate()

      if (success) {
        this.shipmentCity = this.formShipmentCity
        this.shipmentState = this.formShipmentState
        this.shipmentEmail = this.formShipmentEmail
        this.shipmentPhone = this.formShipmentPhone
        this.shipmentColony = this.formShipmentColony
        this.shipmentContact = this.formShipmentContact
        this.shipmentAddress = this.formShipmentAddress
        this.shipmentZipCode = this.formShipmentZipCode

        const formData = new FormData()

        if (this.userCostumer.IdUser) {
          formData.append('id', this.userCostumer.IdUser)
        } else if (this.idCustomer) {
          formData.append('id', this.idCustomer)
        }

        formData.append('saveInfo', this.updateData)

        if (this.addressCostumer) {
          formData.append('idAddress', this.addressCostumer.IdAddress)
        }

        if (this.idShipmentAddress) {
          formData.append('idAddress', this.idShipmentAddress)
        }

        formData.append('city', this.shipmentCity)
        formData.append('state', this.shipmentState)
        formData.append('email', this.shipmentEmail)
        formData.append('phone', this.shipmentPhone)
        formData.append('address', this.shipmentAddress)
        formData.append('postalCode', this.shipmentZipCode)
        formData.append('neighborhood', this.shipmentColony)
        formData.append('contactName', this.shipmentContact)

        // for (const pair of formData.entries()) {
        //   console.log(`${pair[0]}, ${pair[1]}`)
        // }

        try {
          const response = await this.updateShipmentAddress(formData)
          this.idShipmentAddress = response.data.data

          this.showSwalFire('¡Los datos del cliente han sido actualizados correctamente!')
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', getError(error), 'danger')
        }

        this.$nextTick(() => {
          this.$refs.invoiceDataModal.toggle('#toggle-btn')
        })

        this.resetValues()
      }
    },
    async handleShipmentZipCode() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      if (this.formShipmentZipCode.toString().length === 5) {
        this.timeout = setTimeout(async () => {
          const zipCodeInfo = await this.loadZipCode(this.formShipmentZipCode)
          const { data } = zipCodeInfo.data

          data.forEach(element => {
            this.formShipmentColonies.unshift(element.Location)

            if (this.formShipmentCities.indexOf(element.Town) === -1) {
              this.formShipmentCities.unshift(element.Town)
            }

            if (this.formShipmentStates.indexOf(element.State) === -1) {
              this.formShipmentStates.unshift(element.State)
            }
          })
        }, 500)
      } else {
        this.formShipmentCities = []
        this.formShipmentStates = []
        this.formShipmentColonies = []
        this.formShipmentCity = ''
        this.formShipmentState = ''
        this.formShipmentColony = ''
      }
    },
    hideModal() {
      this.$refs.invoiceDataModal.hide()
      this.resetValues()
    },
    resetValues() {
      this.formShipmentCity = ''
      this.formShipmentState = ''
      this.formShipmentEmail = ''
      this.formShipmentPhone = ''
      this.formShipmentColony = ''
      this.formShipmentContact = ''
      this.formShipmentAddress = ''
      this.formShipmentZipCode = ''
      this.updateData = false
    },
    loadExistingShipmentAddress(shipmentAddress) {
      this.shipmentCity = shipmentAddress.City
      this.shipmentState = shipmentAddress.State
      this.shipmentEmail = shipmentAddress.Email
      this.shipmentPhone = shipmentAddress.Phone
      this.shipmentAddress = shipmentAddress.Address
      this.shipmentZipCode = shipmentAddress.PostalCode
      this.shipmentColony = shipmentAddress.Neighborhood
      this.shipmentContact = shipmentAddress.ContactName
    },
    showSwalFire(message) {
      this.$swal.fire({
        title: 'Guardado',
        text: message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        allowOutsideClick: false,
      })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
