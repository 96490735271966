<template>
  <b-card class="border border-dark shadow-none">
    <div>
      <b-row>

        <!-- #region::Title -->
        <b-col class="align-self-end">
          <h4 class="font-weight-bolder">
            Dirección de facturación
          </h4>
        </b-col>
        <!-- #endregion::Title -->

        <!-- #region::Collapsable options -->
        <b-col
          cols="12"
          md="auto"
          class="ml-auto"
        >

          <!-- #region begin::Open modal button -->
          <b-button
            v-if="showUseOtherButton"
            variant="flat-primary"
            size="sm"
            :disabled="!canSetInvoiceAddress"
            @click="openModal"
          >
            {{ optionsButtonText }}
          </b-button>
          <!-- #endregion end::Open modal button -->

          <!-- #region begin::Collapse card button -->
          <b-button
            v-if="billingsCostumer || idInvoiceAddress || mustShowToggleButton"
            :variant="visible ? 'flat-primary' : 'flat-secondary'"
            class="btn-icon rounded-circle ml-1"
            @click="collapse"
          >
            <feather-icon :icon="iconToggle" />
          </b-button>
          <!-- #endregion end::Collapse card button -->

        </b-col>
        <!-- #endregion::Collapsable options -->

      </b-row>
    </div>

    <b-collapse
      :id="collapseItemId"
      v-model="visible"
      role="tabpanel"
    >
      <template v-if="showData">
        <div>
          <hr>
          <h4 class="font-weight-bolder">
            {{ invoiceContact }}
          </h4>
        </div>
        <div class="text-primary">
          {{ invoiceEmail }}
        </div>
        <div>
          {{ invoicePhone }}
        </div>
        <br>
        <div>
          {{ invoiceAddress }}
        </div>
        <div>
          {{ invoiceZipCode }}
        </div>
        <div>
          {{ invoiceColony }}
        </div>
        <div>
          {{ invoiceCity }}
        </div>
        <div>
          {{ invoiceState }}
        </div>
      </template>
      <span v-else>Sin especificar</span>
    </b-collapse>

    <!-- #region::Form modal -->
    <b-modal
      id="invoiceDataModal"
      ref="invoiceDataModal"
      title="Dirección de facturación"
      centered
      size="lg"
      @hide="resetValues"
    >
      <b-card-text>

        <b-alert
          show
          variant="info"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="AlertCircleIcon"
            />
            <span class="ml-25">La información modificada solo será parte de esta cotización. Para guardar la información del cliente haz click en el checkbox de abajo.</span>
          </div>
        </b-alert>

        <validation-observer ref="invoiceDataForm">

          <!-- #region::Form -->
          <b-form @submit.stop.prevent="handleSubmit">
            <b-row>

              <!-- #region::Invoice contact name input -->
              <b-col md="6">
                <TextInputWithValidation
                  v-model="formInvoiceContact"
                  vid="formInvoiceContact"
                  rules="required|min:2"
                  type="text"
                  label="Nombre del contacto"
                  name="contacto"
                  maxlength="200"
                  placeholder="Escribe el nombre del contacto"
                />
              </b-col>
              <!-- #endregion::Invoice contact name input -->

              <!-- #region::Invoice address input -->
              <b-col md="6">
                <TextInputWithValidation
                  v-model="formInvoiceAddress"
                  vid="formInvoiceAddress"
                  rules="required|min:2"
                  type="text"
                  label="Dirección"
                  name="dirección"
                  maxlength="250"
                  placeholder="Escribe la dirección"
                />
              </b-col>
              <!-- #endregion::Invoice address input -->

              <!-- #region::Invoice zip code input -->
              <b-col md="6">
                <TextInputWithValidation
                  v-model="formInvoiceZipCode"
                  vid="formInvoiceZipCode"
                  rules="required|digits:5"
                  type="text"
                  label="Código postal"
                  name="código postal"
                  maxlength="5"
                  placeholder="Escribe el código postal"
                  @input="handleInvoiceZipCode"
                />
              </b-col>
              <!-- #endregion::Invoice zip code input -->

              <!-- #region::Invoice colony select -->
              <b-col md="6">
                <SelectWithValidation
                  v-model="formInvoiceColony"
                  vid="formInvoiceColony"
                  rules="required"
                  name="colonia"
                  label="Colonia"
                  property="name"
                  placeholder="Selecciona la colonia"
                  :options="formInvoiceColonies"
                  :disabled="formInvoiceColonies.length === 0"
                />
              </b-col>
              <!-- #endregion::Invoice colony select -->

              <!-- #region::Invoice city select -->
              <b-col md="6">
                <SelectWithValidation
                  v-model="formInvoiceCity"
                  vid="formInvoiceCity"
                  rules="required"
                  name="ciudad"
                  label="Ciudad"
                  property="name"
                  placeholder="Selecciona la ciudad"
                  :options="formInvoiceCities"
                  :disabled="formInvoiceCities.length === 0"
                />
              </b-col>
              <!-- #endregion::Invoice city select -->

              <!-- #region::Invoice state select -->
              <b-col md="6">
                <SelectWithValidation
                  v-model="formInvoiceState"
                  vid="formInvoiceState"
                  rules="required"
                  name="estado"
                  label="Estado"
                  property="name"
                  placeholder="Selecciona la estado"
                  :options="formInvoiceStates"
                  :disabled="formInvoiceStates.length === 0"
                />
              </b-col>
              <!-- #endregion::Invoice state select -->

              <!-- #region::Invoice email input -->
              <b-col md="6">
                <TextInputWithValidation
                  v-model="formInvoiceEmail"
                  vid="formInvoiceEmail"
                  rules="required|email"
                  type="email"
                  label="Correo electrónico"
                  name="correo electrónico"
                  placeholder="Escribe el correo electrónico"
                />
              </b-col>
              <!-- #endregion::Invoice email input -->

              <!-- #region::Invoice phone input -->
              <b-col md="6">
                <TextInputWithValidation
                  v-model="formInvoicePhone"
                  vid="formInvoicePhone"
                  rules="required|digits:10"
                  type="text"
                  label="Teléfono"
                  name="teléfono"
                  maxlength="10"
                  placeholder="Escribe el número telefónico"
                />
              </b-col>
              <!-- #endregion::Invoice phone input -->

              <b-col md="12">
                <b-form-checkbox
                  v-model="updateData"
                  class="mt-1"
                >
                  Actualizar información del cliente
                </b-form-checkbox>
              </b-col>

            </b-row>
          </b-form>
        </validation-observer>
      </b-card-text>

      <!-- #region::Footer -->
      <template #modal-footer>
        <b-button
          variant="delete-btn"
          class="delete-btn"
          @click="hideModal"
        >
          Cancelar
        </b-button>
        <b-button
          variant="principal-btn"
          class="principal-btn"
          @click="handleReplaceData"
        >
          Usar estos datos
        </b-button>
      </template>
      <!-- #endregion::Footer -->

    </b-modal>
    <!-- #endregion::Form modal -->

  </b-card>
</template>

<script>
// #region Imports
import { mapActions, mapGetters } from 'vuex'
import { ValidationObserver, localize } from 'vee-validate'
import { required, min } from '@validations'
import {
  BCol, BButton, BCard, BRow, BCollapse, BModal, VBModal, BCardText, BForm, BAlert, BFormCheckbox,
} from 'bootstrap-vue'

import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BCard,
    BAlert,
    BModal,
    BButton,
    BCardText,
    BCollapse,
    BFormCheckbox,
    ValidationObserver,
    SelectWithValidation,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    TextInputWithValidation,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    quote: {
      type: Object,
      default: null,
    },
    invoiceAddressData: {
      type: Object,
      default: null,
    },
    showToggleButton: {
      type: Boolean,
      default: false,
    },
    showUseOtherButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      showData: false,
      collapseItemId: '3',
      iconToggle: 'ChevronDownIcon',

      updateData: false,

      formInvoiceCity: '',
      formInvoiceState: '',
      formInvoiceEmail: '',
      formInvoicePhone: '',
      formInvoiceColony: '',
      formInvoiceContact: '',
      formInvoiceAddress: '',
      formInvoiceZipCode: '',

      formInvoiceCities: [],
      formInvoiceStates: [],
      formInvoiceColonies: [],

      // * 31/10/2022 - TAG: Validations
      min,
      required,

      mustShowToggleButton: this.showToggleButton,
    }
  },
  computed: {
    ...mapGetters({
      getRFC: 'quotes/getRFC',
      getIdQuote: 'quotes/getIdQuote',
      getIdCustomer: 'quotes/getIdCustomer',
      getInvoiceCity: 'quotes/getInvoiceCity',
      getInvoiceState: 'quotes/getInvoiceState',
      getInvoiceEmail: 'quotes/getInvoiceEmail',
      getInvoicePhone: 'quotes/getInvoicePhone',
      getInvoiceColony: 'quotes/getInvoiceColony',
      getInvoiceContact: 'quotes/getInvoiceContact',
      getInvoiceAddress: 'quotes/getInvoiceAddress',
      getInvoiceZipCode: 'quotes/getInvoiceZipCode',
      getSelectedCustomer: 'quotes/getSelectedCustomer',
      getIdInvoiceAddress: 'quotes/getIdInvoiceAddress',
    }),
    idQuote: {
      get() { return this.getIdQuote },
      set(value) { this.setIdQuote(value) },
    },
    idInvoiceAddress: {
      get() { return this.getIdInvoiceAddress },
      set(value) { this.setIdInvoiceAddress(value) },
    },
    invoiceCity: {
      get() { return this.getInvoiceCity },
      set(value) { this.setInvoiceCity(value) },
    },
    invoiceState: {
      get() { return this.getInvoiceState },
      set(value) { this.setInvoiceState(value) },
    },
    invoiceEmail: {
      get() { return this.getInvoiceEmail },
      set(value) { this.setInvoiceEmail(value) },
    },
    invoicePhone: {
      get() { return this.getInvoicePhone },
      set(value) { this.setInvoicePhone(value) },
    },
    invoiceColony: {
      get() { return this.getInvoiceColony },
      set(value) { this.setInvoiceColony(value) },
    },
    invoiceContact: {
      get() { return this.getInvoiceContact },
      set(value) { this.setInvoiceContact(value) },
    },
    invoiceAddress: {
      get() { return this.getInvoiceAddress },
      set(value) { this.setInvoiceAddress(value) },
    },
    invoiceZipCode: {
      get() { return this.getInvoiceZipCode },
      set(value) { this.setInvoiceZipCode(value) },
    },
    selectedCustomer: {
      get() { return this.getSelectedCustomer },
    },
    idCustomer: {
      get() { return this.getIdCustomer },
    },
    billingsCostumer() {
      if (this.selectedCustomer) {
        const { billings } = this.selectedCustomer

        if (billings.length > 0) {
          const [billingsCostumer] = billings
          return billingsCostumer
        }

        return false
      }

      return false
    },
    userCostumer() {
      if (this.selectedCustomer) {
        const { user } = this.selectedCustomer

        if (user.length > 0) {
          const [userCostumer] = user
          return userCostumer
        }

        return false
      }

      return false
    },
    optionsButtonText() {
      if (this.selectedCustomer && !this.billingsCostumer && !this.idInvoiceAddress) {
        return 'Agregar'
      }

      return 'Usar otra'
    },
    canSetInvoiceAddress() {
      if (this.selectedCustomer) {
        return true
      }

      if (this.idInvoiceAddress) {
        return true
      }

      if (!this.billingsCostumer) {
        return false
      }

      return true
    },
  },
  watch: {
    selectedCustomer() {
      if (this.billingsCostumer) {
        this.loadExistingInvoiceAddress(this.billingsCostumer)
      } else if (this.visible) {
        this.collapse()
      }
    },
  },
  created() {
    if (this.quote) {
      if (this.quote.billings) {
        const [invoiceInfo] = this.quote.billings
        if (!Array.isArray(invoiceInfo)) {
          this.loadExistingInvoiceAddress(invoiceInfo)
          this.showData = true
        }
      } else {
        this.mustShowToggleButton = false
      }
    }

    if (this.invoiceAddressData) {
      this.idInvoiceAddress = this.invoiceAddressData.IdBillingdata
      this.loadExistingInvoiceAddress(this.invoiceAddressData)
      this.showData = true
    }

    localize('es')
  },
  methods: {
    ...mapActions({
      setIdQuote: 'quotes/setIdQuote',
      loadZipCode: 'wholesalers/loadZipCode',
      setInvoiceCity: 'quotes/setInvoiceCity',
      setInvoiceState: 'quotes/setInvoiceState',
      setInvoiceEmail: 'quotes/setInvoiceEmail',
      setInvoicePhone: 'quotes/setInvoicePhone',
      setInvoiceColony: 'quotes/setInvoiceColony',
      setInvoiceContact: 'quotes/setInvoiceContact',
      setInvoiceAddress: 'quotes/setInvoiceAddress',
      setInvoiceZipCode: 'quotes/setInvoiceZipCode',
      setIdInvoiceAddress: 'quotes/setIdInvoiceAddress',
      updateInvoiceAddress: 'quotes/updateInvoiceAddress',
    }),
    collapse() {
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
    openModal() {
      this.$refs.invoiceDataModal.show()
    },
    handleReplaceData(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      const success = await this.$refs.invoiceDataForm.validate()

      if (success) {
        this.invoiceCity = this.formInvoiceCity
        this.invoiceState = this.formInvoiceState
        this.invoiceEmail = this.formInvoiceEmail
        this.invoicePhone = this.formInvoicePhone
        this.invoiceColony = this.formInvoiceColony
        this.invoiceContact = this.formInvoiceContact
        this.invoiceAddress = this.formInvoiceAddress
        this.invoiceZipCode = this.formInvoiceZipCode

        const formData = new FormData()

        if (this.userCostumer.IdUser) {
          formData.append('id', this.userCostumer.IdUser)
        } else if (this.idCustomer) {
          formData.append('id', this.idCustomer)
        }

        formData.append('saveInfo', this.updateData)

        if (this.billingsCostumer) {
          formData.append('idBilling', this.billingsCostumer.IdBillingdata)
        }

        if (this.billingsCostumer) {
          formData.append('idBilling', this.idInvoiceAddress)
        }

        formData.append('city', this.invoiceCity)
        formData.append('state', this.invoiceState)
        formData.append('email', this.invoiceEmail)
        formData.append('phone', this.invoicePhone)
        formData.append('address', this.invoiceAddress)
        formData.append('postalCode', this.invoiceZipCode)
        formData.append('neighborhood', this.invoiceColony)
        formData.append('contactName', this.invoiceContact)

        // for (const pair of formData.entries()) {
        //   console.log(`${pair[0]}, ${pair[1]}`)
        // }

        try {
          const response = await this.updateInvoiceAddress(formData)
          this.idInvoiceAddress = response.data.data

          this.showSwalFire('¡Los datos del cliente han sido actualizados correctamente!')
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', getError(error), 'danger')
        }

        this.$nextTick(() => {
          this.$refs.invoiceDataModal.toggle('#toggle-btn')
        })

        this.resetValues()
      }
    },
    async handleInvoiceZipCode() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      if (this.formInvoiceZipCode.toString().length === 5) {
        this.timeout = setTimeout(async () => {
          const zipCodeInfo = await this.loadZipCode(this.formInvoiceZipCode)
          const { data } = zipCodeInfo.data

          data.forEach(element => {
            this.formInvoiceColonies.unshift(element.Location)

            if (this.formInvoiceCities.indexOf(element.Town) === -1) {
              this.formInvoiceCities.unshift(element.Town)
            }

            if (this.formInvoiceStates.indexOf(element.State) === -1) {
              this.formInvoiceStates.unshift(element.State)
            }
          })
        }, 500)
      } else {
        this.formInvoiceCities = []
        this.formInvoiceStates = []
        this.formInvoiceColonies = []
        this.formInvoiceCity = ''
        this.formInvoiceState = ''
        this.formInvoiceColony = ''
      }
    },
    hideModal() {
      this.$refs.invoiceDataModal.hide()
      this.resetValues()
    },
    resetValues() {
      this.formInvoiceCity = ''
      this.formInvoiceState = ''
      this.formInvoiceEmail = ''
      this.formInvoicePhone = ''
      this.formInvoiceColony = ''
      this.formInvoiceContact = ''
      this.formInvoiceAddress = ''
      this.formInvoiceZipCode = ''
      this.updateData = false
    },
    loadExistingInvoiceAddress(invoiceAddress) {
      this.invoiceCity = invoiceAddress.City
      this.invoiceState = invoiceAddress.State
      this.invoiceEmail = invoiceAddress.Email
      this.invoicePhone = invoiceAddress.Phone
      this.invoiceAddress = invoiceAddress.Address
      this.invoiceZipCode = invoiceAddress.PostalCode
      this.invoiceColony = invoiceAddress.Neighborhood
      this.invoiceContact = invoiceAddress.ContactName
    },
    showSwalFire(message) {
      this.$swal.fire({
        title: 'Guardado',
        text: message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        allowOutsideClick: false,
      })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
